<template>
  <div class="login-container">
    <div class="content">
      <span class="vip_logo">
        <img id="js_logo_url_preview" :src="dialogImageUrl" />
      </span>
      <div
        class="title"
        style="margin-top: 25px; font-size: 13px; color: gray"
      >
        {{ruleForm.cou_subhead}}
      </div>
      <div class="title" style="margin-top: 5px; font-size: 20px; color: gray">
        {{ ruleForm.card_name }}
      </div>
      <div class="title" style="margin-top: 5px; font-size: 13px; color: gray">
        剩余:{{ruleForm.sri_surp_count}}{{timUnit}}
      </div>
      <el-divider></el-divider>
      <div class="codeinfos">
        <qrcode :text="cardNum" :size="220"></qrcode>
        <barcode :value="cardNum" height="50"> </barcode>
      </div>
      <el-divider></el-divider>
      <div class="infos">
        <span>领取时间：{{ ruleForm.sri_use_dm ? ruleForm.sri_use_dm.replaceAll('T', ' ') : '' }}</span>
        <span>过期时间：{{ ruleForm.over_dm ? ruleForm.over_dm.replaceAll('T', ' ') : '' }}</span>
        <span>使用提醒：{{ ruleForm.cou_reminder }}</span>
        <span>使用须知：{{ ruleForm.cou_know }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import coupons from "../../api/coupons";
import config from "../../config/index";
export default {
  data() {
    return {
      cardNum: "",
      timUnit:"",
      dialogImageUrl: "", //图片回显
      ruleForm: {},
    };
  },
  created() {
    document.title = '使用计次卡';
    this.cardNum = this.$route.query.cardNum;
    this.timUnit=this.$route.query.tim_unit;
    this.getOneGiftInfos(this.cardNum);
  },
  methods: {
    getOneGiftInfos(cardNum) {
      coupons.getOneTimeInfos(cardNum).then((res) => {
        let giftInfo = res.data.data.result[0];
        let timLogoUrl = config.baseUrl + "/" + giftInfo.cou_logo;
        this.dialogImageUrl = timLogoUrl;
        this.ruleForm = res.data.data.result[0];
        console.log(this.ruleForm)
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-divider--horizontal {
  margin: 20px 0 0 0;
}
.login-container {
  height: 100%;
  /*width: 54vh;*/
  padding: 0px 8px;
  overflow: scroll;
  background-color: #409EFF;
  /*background-color: rgb(99, 179, 89);*/
  .content {
    width: 100%;
    /*height: 90%;*/
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 50px;
    .vip_logo {
      position: absolute;
      width: 38px;
      height: 38px;
      padding-top: 0;
      margin-right: 7px;
      border-radius: 22px;
      -moz-border-radius: 22px;
      -webkit-border-radius: 22px;
      border: 1px solid #d3d3d3;
      top: -20px;
      img {
        width: 38px;
        height: 38px;
        border-radius: 22px;
      }
    }
    .codeinfos {
      height: 300px;
      width: 100%;
    }
    .infos {
      line-height: 245%;
      /*height: 150px;*/
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      color: rgb(151, 148, 148);
      padding-left: 20px;
      padding-bottom: 15px;
      font-size: 13px;
    }
  }
}
</style>
