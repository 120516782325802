import { postRequest, getRequest, deleteRequest } from '../utils/request'


export default {
    //获的代金券列表
    list(params) {
        return getRequest('/api/coupons/allcoupons',params)
    },
    changeGiftInfo(status, params){
        console.log(params)
        return postRequest(`/api/giftCard/${status}`, params)
    },
    getOneGifInfos(cardNum){
        return getRequest(`/api/coupons/onecoupons/${cardNum}`)
    },
    getOneTimeInfos(cardNum){
        return getRequest(`/api/coupons/onetimecards/${cardNum}`)
    },
    getAllCoupons(meId){
        return getRequest(`/api/coupons/getAllCoupons/${meId}`)
    }
}
